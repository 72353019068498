export default class CustomTooltipViewModel {
  display_tooltip = false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  displayHelpTooltip = ({ mouseenter, mouseleave }, event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    mouseenter(event);
    this.display_tooltip = !this.display_tooltip;
    if (this.display_tooltip) {
      mouseenter(event);
    }
    if (!this.display_tooltip) {
      mouseleave(event);
    }
  }
}
