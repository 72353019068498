




















import { Component, PropSync, Vue } from 'vue-property-decorator';
import CustomTooltipViewModel
  from '@/vue-app/view-models/components/custom/custom_tooltip_view_model';

@Component({ name: 'CustomTooltip' })
export default class CustomTooltip extends Vue {
  @PropSync('color', { type: String, default: 'sky-blue' })
  synced_color!: string;

  @PropSync('textClassName', { type: String, default: 'primary--text' })
  synced_text_class_name!: string;

  @PropSync('smallTooltip', { type: Boolean, default: false })
  synced_small_tooltip!: boolean;

  @PropSync('label', { type: String, required: true })
  synced_label!: string;

  custom_tooltip_model = Vue.observable(
    new CustomTooltipViewModel(),
  );
}
